/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem} from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd, MdDownload } from 'react-icons/md';
import { useForm, } from "react-hook-form";
import { TextField, MenuItem } from '@mui/material';

const Offices = (props) => {
    const { register, handleSubmit, setValue, reset, formState: { errors }  } = useForm();
    const [offices, setOffices] = useState([]);
    const [officeIdToEdit, setOfficeIdToEdit] = useState(null);
    const [officeToEdit, setOfficeToEdit] = useState(null);
    const [officeIdToDelete, setOfficeIdToDelete] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('Southwest Idaho');
    const [showMailingAddress, setShowMailingAddress] = useState(false);

    const columns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            align: 'right',
            headerAlign: 'right',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                if (!parseInt(id)) return [];
                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setOfficeIdToEdit(id)}}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setOfficeIdToDelete(id); setDeleted(false) }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button" 
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getRegions = async () => {
            apiAdmin.get(`/regions-with-offices`)
                .then((response) => {
                    setRegions(response.data);
                    var officeArr = [];
                    response.data.forEach(r => {
                        officeArr = officeArr.concat(r.offices.map((a) => ({ id: a.officeId, officeName: r.regionName + '/' + a.officeName})))
                    })
                    setOffices(officeArr);
                });
        }
        getRegions();
    }, [submitted, deleted]);

    useEffect(() => {
        if (!officeIdToEdit || officeIdToEdit === -1) return;
        const getOffice = async () => {
            apiAdmin.get(`/office?officeId=${officeIdToEdit}`)
                .then((response) => {
                    setOfficeToEdit(response.data);
                    Object.keys(response.data).forEach(function (key, index) {
                        setValue(key, response.data[key]);
                    });
                    if (response.data.mailingAddress !== '') setShowMailingAddress(true);
                    var region = (regions.filter(f => f.regionId === response.data.regionId)[0])
                    setSelectedRegion(region.regionName);

                });
        }
        getOffice();
    }, [officeIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => { setOfficeIdToEdit(-1); setSelectedRegion('Southwest Idaho'); } }>
                    <MdAdd className="mb-1"/> Add Office
                </span>
                <div className="col">
                    <span className="btn-link btn float-end" onClick={() => {} }>
                        <MdDownload className="mb-1" /> <a href="http://dpvtosql01/ReportServer/Pages/ReportViewer.aspx?%2fTitleOneWeb%2fGetShippingLocations&rs:Command=ClearSession" target="_blank" rel="noreferrer">Download Office List</a>
                    </span>
                </div>
                
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {
        var region = (regions.filter(f => f.regionName === selectedRegion)[0])

        const officeObject = {
            ...data,
            officeId: officeIdToEdit,
            regionId: region.regionId
        }
        const saveOffice = async () => {
            await apiAdmin.post(`/save-office`, officeObject)
                .then((response) => {
                    if (response.data) {
                        setOfficeIdToEdit(null);
                        setOfficeToEdit(null);
                        setSubmitted(true);
                        reset();
                    } else { 
                        setError("There was an error saving the office. Please try again.")
                    }
                });
        }
        saveOffice();
    }

    const deleteOffice = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/delete-office?officeId=`+ officeIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setOfficeIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the office. Please try again.")
                    }
                });
        }
        deleteMessage();
    }
    
    return (
        <div>
            <Helmet>
                <title>Offices</title>
            </Helmet>
            <h1>Offices</h1>
            
            {officeToEdit || officeIdToEdit === -1 ? <>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="listed" {...register("listed")} />
                                <label className="form-check-label" htmlFor="listed">Listed?</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                                select
                                label="Region *"
                                value={selectedRegion}
                                className="w-100"
                                onChange={(e) => setSelectedRegion(e.target.value)}
                            >
                                {regions.map((option) => (
                                    <MenuItem key={"r_" + option.regionId} value={option.regionName}>
                                        {option.regionName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                                                    
                        <div className="mb-3">
                            <TextField
                                label="Office Name *"
                                {...register("officeName", {required: true})}
                                className="w-100"
                            />
                            {errors?.officeName?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Building Name"
                                {...register("buildingName")}
                                className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        label="Physical Address *"
                                        {...register("address1", {required: true})}
                                        className="w-100"
                                    />
                                    {errors?.address1?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-5">
                                    <TextField
                                        label="City *"
                                        {...register("city", {required: true})}
                                        className="w-100"
                                    />
                                    {errors?.city?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>                                   
                                <div className="col-3">
                                    <TextField
                                        label="State *"
                                        {...register("stateAbbrev", {required: true})}
                                        className="w-100"
                                        inputProps={{ maxLength: 2 }}
                                    />
                                    {errors?.stateAbbrev?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>                                
                                <div className="col-4">
                                    <TextField
                                        label="Zip *"
                                        {...register("zip", {required: true})}
                                        className="w-100"
                                        inputProps={{ maxLength: 5 }}
                                    />
                                    {errors?.zip?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" checked={showMailingAddress} id="showMailingAddress" onChange={(e) => setShowMailingAddress(e.target.checked) } />
                                <label className="form-check-label" htmlFor="showMailingAddress">Different Mailing Address?</label>
                            </div>
                        </div>
                        {showMailingAddress && <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        label="Mailing Address *"
                                        {...register("mailingAddress", {required: showMailingAddress})}
                                        className="w-100"
                                        placeholder="i.e. P.O. Box 177"
                                    />
                                    {errors?.mailingAddress?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                        </div>
                        }
                        <div className="mb-3">
                            <TextField
                              label="Phone *"
                                {...register("phone", {required: true})}
                              className="w-100"
                              inputProps={{ maxLength: 10 }}
                            />
                            {errors?.phone?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Fax"
                              {...register("fax")}
                              className="w-100"
                              inputProps={{ maxLength: 10 }}
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="License Number"
                                {...register("licenseNum")}
                                className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Earnnest Link"
                                {...register("earnnestLink")}
                                className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Image"
                                className="w-100"
                                disabled
                                multiline
                                value="Please contact the web team to add both a .jpg and .webp to the images directory. Images should be 350px x 230px. The name of the image should be the office name with spaces separated by dashes. I.e. meridian-rackham-way.jpg."
                            />
                        </div>
                        <div className="mb-3">
                            <div className="row">                                 
                                <div className="col">
                                    <TextField
                                        label="Latitude"
                                        {...register("latitude")}
                                        className="w-100"
                                        inputProps={{ maxLength: 13 }}
                                    />
                                </div>                                
                                <div className="col">
                                    <TextField
                                        label="Longitude"
                                        {...register("longitude")}
                                        className="w-100"
                                        inputProps={{ maxLength: 13 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setOfficeIdToEdit(null); setOfficeToEdit(null); setSubmitted(false); setError(null); reset(); setShowMailingAddress(false); } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Office saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Office deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                
                <DataGridPro
                    rows={offices}
                    columns={columns}
                    loading={offices.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                    autoHeight
                    rowHeight={38}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    treeData
                    getTreeDataPath={(row) => { return row.officeName.split('/'); }}
                    groupingColDef={{
                      minWidth: 300,
                      headerName: "Region"
                    }}
                />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Office?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this office?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteOffice()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Offices;
